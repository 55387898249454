import styled from "styled-components";
const Button = styled.button`
padding: 6px 15px;
background: #1A0572;
border-radius: 15px;
font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 120%;
display: flex;
align-items: center;
text-align: center;
border: 1px solid transparent;
color: #FFFFFF;
cursor: pointer;
transition: all .3s ease-in-out;
&[disabled] {
    background: #D2EFF1;
}
&:hover {
    background: #FFFFFF;
    border: 1px solid #D3FC00;
    color: #1A0572;
}
`;

export const SquareButton = styled.button`
cursor: pointer;
background: #1A0572;
border-radius: 5px;
padding: 13px 23px;
color: #fff;
font-weight: bold;
font-size: 15px;
line-height: 15px;
border: 1px solid transparent;
cursor: pointer;
display: flex;
align-content: center;
align-items: center;
transition: all .3s ease-in-out;

.icon {
    margin-right: 16px;
}

&[disabled] {
    background: #D2EFF1;
}
&:hover {
    background: #FFFFFF;
    border: 1px solid #D3FC00;
    color: #1A0572;

    svg {
        path {
            fill: #1A0572;
        }
    }
}
`;
export default Button;