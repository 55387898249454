import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
justify-content: center;
align-content: center;
align-items: center;
flex-direction: column;
`
export const Top = styled.div`
display: flex;
justify-content: center;
align-content: center;
align-items: center;
flex-direction: column;
position: relative;
width: 100%;
&:after,
&:before {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: matrix(1, 0, 0, -1, 0, 0);
}
&:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    height: 36%;
    z-index: 5;
}
&:after {
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 7;
}

@media screen and (max-width: 1024px) {
    height: 505px;
}
`;
export const Content = styled.div`
max-width: 735px;
width: 100%;
flex-direction: column;
position: absolute;
display: flex;
justify-content: space-between;
align-content: center;
align-items: center;
height: 100%;
padding: 38px 0 16px;
box-sizing: border-box;
color: #fff;
z-index: 10;
font-size: 18px;
line-height: 120%;

p {
    margin: 0 0 12px;
}

@media screen and (max-width: 1280px) {
    max-width: 700px;
    padding-left: 20px;
    padding-right: 20px;
    svg {
        height: 120px
    }
}
@media screen and (max-width: 768px) {
    font-weight: 300;
    font-size: 17px;
    line-height: 130%;
    padding-top: 20px;
    padding-bottom: 20px;

    svg {
        height: 90px
    }
}

`;
export const Img = styled.img`
width: 100%;
max-height: 480px;
object-fit: cover;
@media screen and (max-width: 1024px) {
    max-height: 100%;
    height: 100%;
}
`
export const MapaMobile = styled.div`
display: none;
@media screen and (max-width: 1024px) {
    display: block;
    max-height: 210px;
}
`
export const MapaWrapper = styled.div`
height: 100%;
position: absolute;
right: 50%;
top: 20%;
z-index: 20;
pointer-events: none;
transform: translateX(700px);
img {
    max-width: 100%;
    object-fit: contain;
}
@media screen and (max-width: 1280px) {
    height: auto;
    max-width: 22%;
    right: 20px;
    top: auto;
    bottom: -50px;
    transform: translateX(0);
}
@media screen and (max-width: 1024px) {
    display: none;
}
`
export const Mapa = styled.img`
height: 100%;
`
export const Text = styled.div`
display: flex;
align-content: center;
align-items: center;
justify-items: center;
justify-content: center;
max-width: 735px;
width: 100%;
flex-direction: column;
margin-top: -13px;
z-index: 30;
position: relative;
padding-top: 0;
box-sizing: border-box;
transition: padding .4s ease;

.open & {
    padding-top: 13px;
}
p {
    margin: 0 0 30px;
    color: #595959;
    font-weight: 300;
    font-size: 18px;
    line-height: 120%;

    &:last-child {
        margin-bottom: 0;
    }
}
@media screen and (max-width: 1280px) {
    max-width: 700px;
    padding-right: 20px;
    padding-left: 20px;
}
@media screen and (max-width: 768px) {
    p {
        font-weight: 300;
        font-size: 17px;
        line-height: 130%;
    }
}
`;