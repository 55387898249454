import Main from './layout/Main'; 
import { QueryClientProvider } from 'react-query'
import {queryClient} from './requests';

const App = props => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </div>
  );
}

export default App;
