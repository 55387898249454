import { useQuery } from 'react-query'
import AOS from 'aos';
import { animateScroll } from "react-scroll";
import Header from '../Header';
import Footer from '../Footer';
import Content from '../Content';
import DataContext, {defaultContext} from '../../context';
import { getEstados, getVagas, getEmpresas } from '../../requests';
import { useCallback, useState, useEffect } from 'react';
import 'aos/dist/aos.css';

const Main = props => {
    const [estado, setEstado] = useState(false);
    const [empresa, setEmpresa] = useState(false);

    const setActiveEstado = useCallback(setEstado, [setEstado])
    const setActiveEmpresa = useCallback(setEmpresa, [setEmpresa])

    const { isLoading:isLoadingEstados, data:estados=[] } = useQuery('repoEstados', getEstados)
    const { isLoading:isLoadingVagas, data:vagas=[] } = useQuery('repoVagas', getVagas)
    const { isLoading:isLoadingEmpresas, data:empresas=[] } = useQuery('repoEmpresas', getEmpresas)
    // use effect
    useEffect(() => {
      animateScroll.scrollTo(0);
      AOS.init({ duration : 400, once: true, });
    }, [ ]);
    return (<DataContext.Provider value={{...defaultContext,
          estados:{data:estados, isLoading:isLoadingEstados, active: estado},
          vagas:{data:vagas, isLoading:isLoadingVagas},
          empresas:{data:empresas, isLoading:isLoadingEmpresas, active: empresa},
          filterEstado: setActiveEstado,
          filterEmpresa: setActiveEmpresa,
        }}>
          <Header />
          <Content />
          <Footer />
        </DataContext.Provider>);
};
export default Main;