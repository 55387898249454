import { useState, useContext, useCallback, useMemo, useEffect } from "react";
import Lupa from '../../icons/Lupa';
import Select from '../../components/Select';
import {SquareButton} from '../../components/Button';
import DataContext from '../../context';
import { Form } from './styles';
const FilterForm = props => {
    const {estados, empresas, filterEstado, filterEmpresa} = useContext(DataContext);
    const [estado, setEstado] = useState(false);
    const [empresa, setEmpresa] = useState(false);

    useEffect( _ => {
        if (!estados.active) {
            setEstado(false);
        }
        if (!empresas.active) {
            setEmpresa(false);
        }
    }, [empresas, estados]);
    const onChangeEstado = useCallback((index, option) => setEstado(estados.data.find( item => item.nome === option)), [estados]);
    const onChangeEmpresa = useCallback((index, option) => setEmpresa(empresas.data.find( item => item.nome === option)), [empresas]);
    const listEstados = useMemo(() => ['Selecionar Local', ...estados.data.map(item => item.nome)], [estados]);
    const listEmpresas = useMemo(() => ['Selecionar Empresa', ...empresas.data.map(item => item.nome)], [empresas]);


    return (<Form data-aos="fade-down" data-aos-delay={300} >
            <label htmlFor="local">
                <Select name="local" id="local" label={estado?.nome || 'Selecionar Local'} onChange={onChangeEstado} options={listEstados} />
            </label>
            <label htmlFor="empresa">
                <Select name="empresa" id="empresa" label={empresa?.nome || 'Selecionar Empresa'} onChange={onChangeEmpresa} options={listEmpresas} />
            </label>
            <SquareButton onClick={ (event) => {
                event.preventDefault();
                event.stopPropagation();
                filterEstado(estado);
                filterEmpresa(empresa);
            }}>
                <Lupa className="icon" />
                Buscar
            </SquareButton>
        </Form>);
}
export default FilterForm;