import styled from "styled-components";
import FilterForm from '../FilterForm';
import ListaVagas from '../ListaVagas';


const Wrapper = styled.section`
    padding: 40px 0 80px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        padding-top: 31px;
    }
`;

const Content = props => {
    return <Wrapper>
        <FilterForm />
        <ListaVagas />
    </Wrapper>
}
export default Content;