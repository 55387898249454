const Lupa = ({fill, width, height, className}) => <svg className={className} width={width} height={height} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.809 20.038L16.8574 14.303C16.6347 14.0882 16.6121 13.7566 16.7846 13.5027C19.2245 9.92352 18.6554 5.00029 15.0559 2.06018C11.5925 -0.769032 6.31915 -0.671605 2.9642 2.27434C-0.888311 5.65714 -0.985709 11.4143 2.67294 14.9161C5.79367 17.9031 10.6223 18.2664 14.1692 16.0224C14.4356 15.854 14.7846 15.8756 15.0092 16.0924L20.9472 21.8144C21.2039 22.0613 21.621 22.062 21.8785 21.8157L22.8076 20.9263C23.0637 20.6807 23.0641 20.2834 22.809 20.038ZM4.53196 13.1373C1.99186 10.7059 1.99256 6.75084 4.53196 4.32077C7.07065 1.89002 11.2022 1.89002 13.7418 4.32077C16.281 6.75151 16.281 10.7065 13.7418 13.1373C11.2022 15.5676 7.07135 15.5682 4.53196 13.1373Z" fill={fill} />
</svg>;

Lupa.defaultProps = {
    fill: "white",
    width: 23,
    height: 22,
};

export default Lupa;