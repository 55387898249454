import styled from "styled-components";

export const Lista = styled.div`
display: flex;
flex-wrap: wrap;
max-width: 1400px;
width: 100%;
justify-content: flex-start;

@media screen and (max-width: 1420px) {
    padding: 0 20px;
    box-sizing: border-box;
}

> div {
    @media screen and (min-width: 1101px) {
        width: 24%;
        margin-right: 1.33%;
        margin-bottom: 1.33%;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 1100px) and (min-width: 769px)  {
        width: 32.5%;
        margin-right: 1.25%;
        margin-bottom: 1.25%;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 768px) and (min-width: 481px)  {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 2%;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 20px;
    }
}
`;
export const P = styled.p`
display: flex;
justify-content:center;
justify-items:center;
width: 100%;
white-space: break-spaces;
font-size: 18px;
font-weight: bold;
color: #434343;
text-align: center;

span {
    display: block;
}
`;
export const EmptyList = styled.div`
display: flex;
width: 100%;
justify-content: center;
justify-items: center;
flex-direction: column;
align-items: center;
align-content: center;
`;