import { useState, useCallback, useRef, useEffect } from 'react';
import { Wrapper, Label, Options, List, Option, Icon } from './styles';
import Arrow from '../../icons/Arrow';
const Select = ({className, id, label, options, onChange }) => {
    const [open, setOpen] = useState(false);
    const content = useRef(null);
    const onClickOutside = useCallback((event) => {
        if (open && !content?.current.contains(event.target)) {
            setOpen(false);
        }
    }, [open, content, setOpen])
    useEffect(() => {
        window.addEventListener('click', onClickOutside);
        return () => window.removeEventListener('click', onClickOutside);
    }, [onClickOutside])

    const getHeight = useCallback( () => {
        const height = open ? 4*49 : 0;
        return height
    }, [open]);

    const getClass = useCallback( () => {
        return (className || '') + ' ' + (open ? 'open':'');
    }, [open, className]);

    const attributes = {
        id: id,
        className: getClass()
    }

    const onClickOption = (index, option) => {
        setOpen(false);
        onChange && onChange(index, option);
    }

    return (<Wrapper {...attributes}>
        <Label onClick={ _ => setOpen(!open)}>
            {label}
            <Icon>
                <Arrow direction={open ? 'up':'down'} />
            </Icon>
        </Label>
        <Options className={open ? "open":""} style={{maxHeight: getHeight()}}>
            <List ref={content}>
                {options?.length > 0 && options.map ( (option, index) => <Option onClick={_ => onClickOption(index, option)} key={index}>{option}</Option>)}
            </List>
        </Options>
    </Wrapper>);
}

export default Select;