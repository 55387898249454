import { useCallback } from "react";
const Arrow = ({width, height, stroke, direction}) => {
    const getTransform = useCallback( () => {
        
        switch (direction) {
            case 'left': return '90';
            case 'up': return '180';
            case 'right': return '270';
            case 'down':
            default:
                return '0';
        }
    }, [direction])
    return (<svg width={width} height={height} style={{ transform: `rotate(${getTransform()}deg)`, transition: 'transform .4s ease'}}viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2L7 7L12 2" stroke={stroke} strokeWidth={3} strokeLinecap="round" />
    </svg>);
};

Arrow.defaultProps = {
    stroke: "#1A0572",
    width:14,
    height:10, 
}
export default Arrow;