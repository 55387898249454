import {useState} from 'react';
import header from  './header.png';
import { Wrapper, Top, Content, Img, Mapa, MapaWrapper, MapaMobile, Text } from './styles';
import mapa from  './mapa.png';
import Button from  '../../components/Button';
import Logo from  '../../components/misc/Logo';
import Collapse from  '../../components/Collapse';
const Header = props => {
    const [open, setOpen] = useState(false);
    return (<div className={"header" + (open ? ' open':'')}>
        <Wrapper>
            <Top>
                <Content>
                    <a href="/">
                    <Logo data-aos="fade-down" data-aos-delay={200} />
                    </a>
                    <MapaMobile>
                        <Mapa data-aos="fade-down" data-aos-delay={400} src={mapa} />
                    </MapaMobile>
                    <p data-aos="fade" data-aos-delay={600}>O Mural Óleo e Gás é uma iniciativa da Brava Energia que tem como objeto dar publicidade às oportunidades de empregos oferecidos pela Companhia e por seus fornecedores.</p>
                </Content>
                <MapaWrapper>
                    <Mapa data-aos="fade" data-aos-delay={400} src={mapa} />
                </MapaWrapper>
                <Img src={header} />
            </Top>
            <Text data-aos="fade" data-aos-delay={600}>
                <Collapse open={open}>
                    <p>O Mural possibilita à população local se candidatar para vagas oferecidas pela Brava Energia, bem como impulsiona a divulgação das vagas oferecidas por seus fornecedores, a partir da disponibilização dos contatos dos Recursos Humanos desses fornecedores para o envio de currículos e, desta forma, a participação em processos seletivos.</p>
                    <p>A Brava Energia ressalta que o objetivo do Mural é ampliar a divulgação das suas vagas e de seus fornecedores. Vale ressaltar que a Companhia não possui qualquer ingerência quanto às vagas oferecidas por seus fornecedores e que os processos seletivos para preenchimento de tais vagas serão conduzidos diretamente por eles.</p>
                </Collapse>
                <Button onClick={_ => setOpen(!open)}>{!open ? 'saiba mais':'saiba menos'}</Button>
            </Text>
        </Wrapper>
    </div>);
};
export default Header;