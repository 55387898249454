import { useCallback, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease;
    > div {
        padding: 20px 0;
    }
`
const Collapse = ({open, children}) => {
    const content = useRef(null);
    const getHeight = useCallback(() => {
        return content?.current && open ? content.current.getBoundingClientRect().height : 0
    }, [open, content]);
    return (
        <Wrapper style={{maxHeight: getHeight()}}>
            <div ref={content}>{children}</div>
        </Wrapper>
    );
};
export default Collapse;