import styled from "styled-components"
import logo from './logo.png';

const Img = styled.img`
height: auto;
width: auto;
margin-right: 20px;
`;
const P = styled.p`
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
color: #434343;
@media screen and (max-width: 768px) {
    span {
        display: block;
    }
}
`;
const Wrapper = styled.footer`
display: flex;
justify-content: center;
justify-items: center;
align-content: center;
align-items: center;
border-top: 1px solid #D0D0D0;
padding: 13px 0;
`;
const Footer = () => <Wrapper>
    <Img src={logo} />
    <P><span>© Copyright 2021 -</span> Todos os direitos reservados a Mural O&G</P>
</Wrapper>;

export default Footer;