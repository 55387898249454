import { createContext } from "react"
const defaultContext = {
    estados: {
        data: [],
        active: false,
        isLoading: false,
    },
    vagas: {
        data: [],
        isLoading: false,
    },
    empresas: {
        data: [],
        active: false,
        isLoading: false,
    },
    isLoading: false
}
const DataContext = createContext(defaultContext);

export {defaultContext};
export default DataContext;