import { URL_API_ESTADOS, URL_API_EMPRESAS, URL_API_VAGAS } from './constants';
import { QueryClient } from 'react-query';
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
    },   
})
const getEstados = _ => fetch(URL_API_ESTADOS).then(res => res.json())
const getVagas = _ => fetch(URL_API_VAGAS).then(res => res.json())
const getEmpresas = _ => fetch(URL_API_EMPRESAS).then(res => res.json())

export { queryClient, getEstados, getVagas, getEmpresas };