import { Fragment, useMemo } from "react";
import { useContext } from "react";
import DataContext from "../../context";
import { Wrapper, Label, Title, SubTitle, Link, Button, Mapa } from "./styles";
const Card = ({empresa, estado, link, curriculo, delay}) => {
    const {estados} = useContext(DataContext);
    const model = useMemo( _ => estados.data.find( item => item.sigla === estado) || '', [estados, estado])
    const target = useMemo( _ => link.indexOf('//') === -1? '': '_blank', [link])
    return (<Wrapper data-aos={'zoom-in'} data-aos-delay={delay}>
        <div>
            <Label>EMPRESA</Label>
            <Title>{empresa}</Title>
        </div>
        <div>
            <Label>LOCAL</Label>
            <SubTitle>{model?.nome || estado}</SubTitle>
        </div>
        <div>
        {curriculo && <Fragment>
            <Label>ENVIAR CURRICULO POR E-MAIL</Label>
            <Link href={"mailto:"+curriculo}>{curriculo}</Link>
        </Fragment>}
        </div>
        {link && <Button href={link} target={target}>ver vagas</Button>}
        {model && <Mapa src={model.thumb} />}
    </Wrapper>);
};
export default Card;