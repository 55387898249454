import Button from  '../../components/Button';
import { useContext, useEffect, useState, useCallback } from "react";
import DataContext from '../../context';
import Card from '../../components/Card';
import { useMediaQuery } from 'react-responsive'
import { Lista, P, EmptyList } from "./styles";

const ListaVagas = () => {
    const {vagas, estados, empresas, filterEstado, filterEmpresa} = useContext(DataContext);
    const [items, setItems] = useState([]);

    const is4Columns = useMediaQuery({ query: '(min-width: 1101px' });
    const is3Columns = useMediaQuery({ query: '(max-width: 1100px) and (min-width: 769px)' });
    const is2Columns = useMediaQuery({ query: '(max-width: 768px) and (min-width: 481px)' });
    const is1Columns = useMediaQuery({ query: '(max-width: 480px' });

    const getTime = useCallback((index) => {
        const defaultTimer = 200;
        let divid, timer;
        if (is4Columns) {
            divid = 4; timer = [4,1,2,3];
        }
        else if (is3Columns) {
            divid = 3; timer = [3,1,2];
        }
        else if (is2Columns) {
            divid = 2; timer = [2,1];
        }
        if (divid) return timer[(index + 1) % divid] * defaultTimer
        return defaultTimer;
    }, [is4Columns, is3Columns, is2Columns])
    const resetFilter = () => {
        filterEstado(false);
        filterEmpresa(false);
    }
    const getItems = useCallback(() => {
        return vagas?.data.filter((item) => (!estados.active || item.estado === estados.active.sigla) && (!empresas.active || item.empresa === empresas.active.nome));
    }, [vagas, estados, empresas]);
    useEffect(() => {setItems([]); setTimeout(() => setItems(getItems()), 1)}, [vagas, estados, empresas, getItems]);
    if (vagas.isLoading) return <p>Carregando</p>;

    return (items.length > 0 && (<Lista>
         {items.map( (item, i) => <Card delay={getTime(i)} key={i} {...item} />)}
    </Lista>)) || (items.length === 0 && (<EmptyList>
            <P>Não foi encontrada nenhuma vaga.</P>
            <Button onClick={resetFilter}>Voltar</Button>
        </EmptyList>));
}

export default ListaVagas;
