import styled from "styled-components";

export const Form = styled.form`
display: flex;
justify-content: center;
margin-bottom: 48px;
width: 100%;
padding: 0 20px;
box-sizing: border-box;
flex-wrap: wrap;
z-index: 1;

> * {
    max-width: 245px;
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }
}
> label {
    width: 100%;
}

@media screen and (max-width: 768px) {
    margin-bottom: 35px;
    > * {
        width: 100%;   
        max-width: 100%;   
        margin-right: 0;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    button {
        justify-content: center;
        justify-items: center;
    }
}
`;
